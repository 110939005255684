import React, { useEffect, useState } from 'react';
// import readingTime from 'reading-time';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import MediaAsset from 'components/UI/MediaAsset';
import {
  ArticleCardWrapper,
  MediaAssetWrapper,
  ContentWrapper,
  TagsStyled,
  TagStyled,
  TitleStyled,
  BodyStyled,
  BottomPanelStyled,
  DateStyled,
  ReadingTimeStyled,
  TimeValue,
  ContentWrapperDetails,
} from './styles';
import { formatDateToFr } from 'utils/functions/formatDateToFr';

const ArticleCard = ({
  height,
  heightMobile,
  color = 'blue',
  big = false,
  path,
  article,
}) => {
  const {
    placeholderAsset,
    asset,
    tags,
    title,
    date: enDate,
    handle,
    excerpt,
    body,
  } = article;

  const date = formatDateToFr(enDate);

  const [showBody, setShowBody] = useState(false);

  const rawText = body?.raw
    ? documentToReactComponents(JSON.parse(body?.raw))
        ?.map((c) => c.props.children)
        ?.flat()
        ?.filter((c) => typeof c !== 'object')
        ?.join('')
    : rawHtmlFromImport?.rawHtmlFromImport;

  // const stats = rawText ? readingTime(rawText) : '';

  useEffect(() => {
    if (big) setShowBody(true);
  }, [big]);

  return (
    <ArticleCardWrapper
      className={`Article-Card ${color} ${big ? 'big' : 'small'}`}
      color={color}
    >
      {placeholderAsset ? (
        <MediaAssetWrapper
          heightMobile={heightMobile}
          height={height}
          className="MediaAssetWrapper"
          to={`${path}/${handle}`}
        >
          <MediaAsset image={placeholderAsset} />
          {tags.includes('Video') && (
            <Icon type="play-circle" className="Icon-Play" />
          )}
        </MediaAssetWrapper>
      ) : (
        asset && (
          <MediaAssetWrapper
            heightMobile={heightMobile}
            height={height}
            className="MediaAssetWrapper"
            to={`${path}/${handle}`}
          >
            <MediaAsset image={asset} />
            {tags.includes('Video') && (
              <Icon type="play-circle" className="Icon-Play" />
            )}
          </MediaAssetWrapper>
        )
      )}
      <ContentWrapper big={big} className="ContentWrapper">
        {tags && (
          <TagsStyled>
            {tags.map((t) => (
              <TagStyled big={big} key={t}>
                {t}
              </TagStyled>
            ))}
          </TagsStyled>
        )}
        <ContentWrapperDetails className="ContentWrapperDetails">
          {title && <TitleStyled to={`${path}/${handle}`}>{title}</TitleStyled>}
          {excerpt && showBody && (
            <BodyStyled theme="body">{excerpt}</BodyStyled>
          )}
          <BottomPanelStyled className="BottomPanel">
            {date && <DateStyled theme="body">{date}</DateStyled>}
            {/* {stats?.minutes && (
              <ReadingTimeStyled>
                <Icon type="clock" />
                <TimeValue theme="body">{`${Math.ceil(
                  stats?.minutes
                )} min.`}</TimeValue>
              </ReadingTimeStyled>
            )} */}
          </BottomPanelStyled>
        </ContentWrapperDetails>
      </ContentWrapper>
    </ArticleCardWrapper>
  );
};

ArticleCard.propTypes = {
  color: PropTypes.string,
  big: PropTypes.bool,
  path: PropTypes.string.isRequired,
  article: PropTypes.object.isRequired,
  height: PropTypes.string,
  heightMobile: PropTypes.string,
};

export default ArticleCard;
