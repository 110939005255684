import React from 'react';
import { graphql } from 'gatsby';
import Blog from 'components/Layouts/Blog';

const BlogTemplate = (props) => <Blog {...props} />;

export default BlogTemplate;

export const blogQuery = graphql`
  query($handle: String!) {
    contentfulBlogPage(handle: { eq: $handle }) {
      contentful_id
      id
      title
      modules {
        title
        excerpt
      }
    }
    allContentfulArticlePage(sort: {fields: date, order: DESC}) {
      edges {
        node {
          articleTheme {
            title
            blog___page {
              handle
            }
          }
          title
          tags
          id
          contentful_id
          excerpt
          handle
          body {
            raw
          }
          asset {
            gatsbyImageData(aspectRatio: 1.55, placeholder: NONE)
            title
          }
          placeholderAsset {
            gatsbyImageData(aspectRatio: 1.55, placeholder: NONE)
            title
          }
          articleTheme {
            title
          }
          date(formatString: "DD MMMM YYYY")
        }
      }
    }
  }
`;
