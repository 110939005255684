import React from 'react';
import PropTypes from 'prop-types';
import ArticleCard from 'components/ArticleCard';
import { BlogHeadWrapper } from './styles';

const BlogHead = ({ path, card }) => {
  return (
    <BlogHeadWrapper>
      <ArticleCard color="blue" big={true} path={path} article={card} />
    </BlogHeadWrapper>
  );
};

BlogHead.propTypes = {
  path: PropTypes.string.isRequired,
  card: PropTypes.object.isRequired,
};

export default BlogHead;
