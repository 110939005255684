import React from 'react';
import { BackToTopButton } from './styles';

const BackToTop = ({ name }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <BackToTopButton
      type="button"
      role="button"
      title={name}
      onClick={() => scrollToTop()}
      className="BackToTopButton"
    >
      {name} ↑
    </BackToTopButton>
  );
};

export default BackToTop;
